import { Icon } from '@gimlite/watermelon/components/icon/icon.component';
import type { Placement2Axis, Size } from '@gimlite/watermelon';
import { Drawer as DrawerAnt } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import './drawer.component.scss';
import { createContext } from 'react';

export const DrawerContext = createContext<{
  close: () => void;
  open: () => void;
} | null>(null);

export declare namespace DrawerType {
  type Props = {
    className?: string;
    children?: DrawerType.Data;
    data: React.ReactNode;
    handleEvent?: {
      close?: () => void;
    };
    config?: {
      placement?: Extract<Placement2Axis, 'top' | 'left' | 'right' | 'bottom'>;
      size?: Extract<
        Size,
        | 'xsmall'
        | 'small'
        | 'medium'
        | 'xmedium'
        | 'large'
        | 'xlarge'
        | 'xxlarge'
      >;
      isOpen?: boolean;
    };
  };

  type Data = React.ReactNode;
}

export const Drawer = ({
  className = '',
  children,
  data,
  config: { placement = 'right', size = 'medium', isOpen } = {},
  handleEvent: { close } = {},
}: DrawerType.Props) => {
  const [open, setOpen] = useState(false);

  const orientation = useMemo(
    () => (['right', 'left'].includes(placement) ? 'horizontal' : 'vertical'),
    [placement],
  );

  useEffect(() => {
    setOpen(false);
    close?.();
  }, [location.pathname]);

  useEffect(() => {
    if (isOpen) setOpen(isOpen);
    if (isOpen === false) close?.();
  }, [isOpen]);

  return (
    <DrawerContext.Provider
      value={{
        open: () => setOpen(true),
        close: () => {
          close?.();
          setOpen(false);
        },
      }}
    >
      {children && (
        <div onClick={() => setOpen(true)} className="drawertrigger">
          {children}
        </div>
      )}
      <DrawerAnt
        forceRender={true}
        destroyOnClose={true}
        rootClassName={`
          drawer
          drawer--${orientation}--${size} 
          ${className}
        `}
        placement={placement}
        size={'default'}
        onClose={() => {
          setOpen(false);
          close?.();
        }}
        open={open}
        extra={
          <div
            className="drawer__close"
            onClick={() => {
              setOpen(false);
              close?.();
            }}
          >
            <Icon
              config={{
                size: 'xxlarge',
                type: 'faXmarkSolid',
                color: 'yang',
              }}
            />
          </div>
        }
      >
        {data}
      </DrawerAnt>
    </DrawerContext.Provider>
  );
};
