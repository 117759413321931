import { ApolloError } from '@apollo/client';
import { dispatch } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestGQL } from '../../functions/request.function';
import { toCapitalizeCase } from '../../functions/string.function';
import {
  digitRule,
  lowercaseLetterRule,
  minLengthPasswordRule,
  specialCharRule,
  uppercaseLetterRule,
} from '../../functions/validator.function';
import { updatePasswordGql } from '../../gql/updatePassword.gql';
import { useDevice } from '../../hook/useDevice.hook';
import { useRootStore } from '../../hook/useRootStore.hook';
import { useTranslation } from '../../hook/useTranslation.hook';
import { notifService } from '../../machines/notif.machine';
import {
  LangContext,
  translationService,
} from '../../machines/translation.machine';
import { wizardService } from '../../machines/wizard.machine';
import { SSOContextType } from '../../store/sso.store';
import type { Position } from '../../types/component.type';
import { Avatar } from '../avatar/avatar.component';
import { Button } from '../button/button.component';
import { Col } from '../col/col.component';
import { Drawer, DrawerContext, DrawerType } from '../drawer/drawer.component';
import { Form } from '../form/form.component';
import { Icon, IconType } from '../icon/icon.component';
import { Input } from '../input/input.component';
import { Popover } from '../popover/popover.component';
import { Radio, RadioGroup } from '../radio/radio.component';
import { Select } from '../select/select.component';
import { Space } from '../space/space.component';
import { Write } from '../write/write.component';
import './header.component.scss';

class Static {
  public static flagLangException: Record<string, string> = {
    EN: 'GB',
  };

  public static flagLang = (value: string): string => {
    const labeled = Static.langLabeled(value);
    const flagException = Static.flagLangException[labeled];
    return flagException ? flagException : labeled;
  };

  public static langLabeled = (lang: string): string => lang.split('_')[1];
}

export declare namespace HeaderGroupSectionType {
  type Props = {
    handleEvent: {
      close: () => void;
    };
    className?: string;
    data: {
      key: string;
      disabled?: boolean;
      items: Array<HeaderGroupSectionType.Data.Item | null>;
    };
  };

  namespace Data {
    type Item = {
      key: string;
      icon?: IconType.Config.Type;
      label: string;
      extra?:
        | { type: 'value'; text: string }
        | { type: 'drawer'; node: DrawerType.Data }
        | { type: 'event'; event: () => void }
        | ({ type: 'depth' } & HeaderGroupSectionType.Props['data']);
      disabled?: boolean;
    };
  }
}

const HeaderGroupSection = ({
  data: { items, disabled },
  handleEvent: { close },
}: HeaderGroupSectionType.Props) => {
  const { isDesktop } = useDevice();

  return items.filter((v) => v !== null).length > 0 ? (
    <div className="accountManager__account__group">
      {items
        .filter((v) => v !== null)
        .filter(({ disabled = false }) => !disabled)
        .map(({ key, icon, label, extra }) => {
          return (
            <div key={key} className="accountManager__account__group__option">
              <div className="accountManager__account__group__option__subject">
                {icon && (
                  <Icon
                    className="accountManager__account__group__option__subject__icon"
                    config={{
                      type: icon,
                      color: 'label',
                      size: 'xsmall',
                    }}
                  ></Icon>
                )}
                <div className="accountManager__account__group__option__subject__label">
                  <Write
                    data={{ item: label }}
                    config={{
                      mode: 'label-value',
                      color: 'yang',
                      wrap: false,
                    }}
                  />
                </div>
              </div>
              {extra && (
                <div className="accountManager__account__group__option__extra">
                  {extra.type === 'drawer' ? (
                    <Drawer
                      data={extra.node}
                      config={{
                        size: isDesktop ? 'xsmall' : 'large',
                        placement: isDesktop ? 'right' : 'bottom',
                      }}
                    >
                      {
                        <Icon
                          config={{
                            color: 'primary',
                            type: 'faChevronRightSolid',
                            size: 'xxsmall',
                          }}
                        />
                      }
                    </Drawer>
                  ) : extra.type === 'event' ? (
                    <div
                      onClick={() => {
                        console.log('event porifnc');
                        extra.event();
                        close();
                      }}
                      className="event-trigger"
                    ></div>
                  ) : extra.type === 'value' ? (
                    <Write
                      data={{ item: extra.text }}
                      config={{ mode: 'label' }}
                    ></Write>
                  ) : (
                    <Drawer
                      data={
                        <HeaderGroupSection
                          handleEvent={{
                            close: () => {
                              close();
                            },
                          }}
                          data={{
                            items: extra.items,
                            key: extra.key,
                            disabled: extra.disabled,
                          }}
                        />
                      }
                      config={{
                        size: isDesktop ? 'xsmall' : 'large',
                        placement: isDesktop ? 'right' : 'bottom',
                      }}
                    >
                      {
                        <Icon
                          config={{
                            color: 'primary',
                            type: 'faChevronRightSolid',
                            size: 'xxsmall',
                          }}
                        />
                      }
                    </Drawer>
                  )}
                </div>
              )}
            </div>
          );
        })}
    </div>
  ) : null;
};

declare namespace HeaderGroupType {
  type Props = {
    isConnected: boolean;
    features: {
      shortcut?: HeaderType.Config.Shortcut[];
      tfa?: { state: boolean; email: string };
      updatePassword?: boolean;
      lang?: boolean;
      theme?: boolean;
      versions?: {
        environment?: string;
        watermelon?: string;
        front?: string;
      };
      logout: () => void;
    };
  };
}

const HeaderGroup = ({
  isConnected,
  features: {
    shortcut,
    tfa,
    updatePassword,
    lang = true,
    theme = true,
    versions,
    logout,
  },
}: HeaderGroupType.Props) => {
  const { ThemeStore } = useRootStore();
  const { t } = useTranslation();
  const [passwordValidFormat, setPasswordValidForm] = useState(false);
  const [passwordValidSame, setPasswordValidSame] = useState(false);
  const [passwordValues, setPasswordValues] = useState<Object>({});

  const langItems = useSelector(
    translationService,
    ({ context }: { context: LangContext }) => context.items,
  );

  const langSelected = useSelector(
    translationService,
    ({ context }: { context: LangContext }) => context.lang,
  );

  return (
    lang && (
      <DrawerContext.Consumer>
        {(drawer) => {
          return [
            {
              key: 'group1',
              items: [
                shortcut && isConnected
                  ? {
                      key: 'shortcut',
                      label: t('watermelon-shortcut'),
                      icon: 'faArrowsUpDownLeftRightSolid',
                      extra: {
                        type: 'depth',
                        key: 'shortcut',
                        items: shortcut
                          ? shortcut.map(({ event, label, icon }, index) => ({
                              key: `form-${index}`,
                              label,
                              icon,
                              extra: {
                                type: 'event',
                                event: () => {
                                  event();
                                },
                              },
                            }))
                          : [],
                      },
                    }
                  : null,
              ] as Array<HeaderGroupSectionType.Data.Item | null>,
            },
            {
              key: 'group2',
              items: [
                tfa && isConnected
                  ? {
                      key: '2fa',
                      label: !!tfa?.state
                        ? t('watermelon-deactivate-2FA')
                        : t('watermelon-activate-2FA'),
                      icon: 'partnerGoogleAuthentificator',
                      extra: {
                        type: 'event',
                        event: () => {
                          wizardService.send('SHOW_WIZARD', {
                            id: 'state2FA',
                            store: {
                              tfaActivated: !!!tfa?.state,
                              email: tfa?.email,
                            },
                          });
                        },
                      },
                    }
                  : null,
                updatePassword && isConnected
                  ? {
                      key: 'password',
                      label: t('watermelon-update-password'),
                      icon: 'faLockSolid',
                      extra: {
                        type: 'drawer',
                        node: (
                          <Form
                            data={{
                              value: passwordValues,
                            }}
                            handleEvent={{
                              submit: async ({
                                currentPassword,
                                newPassword,
                              }: any) => {
                                try {
                                  const { success } = await requestGQL({
                                    gql: updatePasswordGql,
                                    params: {
                                      input: { currentPassword, newPassword },
                                    },
                                  });
                                  if (success) {
                                    notifService.send('NOTIF', {
                                      mode: 'success',
                                      content: t(
                                        'watermelon-thePasswordHasBeenChanged',
                                      ),
                                    });
                                    setPasswordValues(() => {});
                                  } else {
                                    notifService.send('NOTIF', {
                                      mode: 'error',
                                      content: t(
                                        'watermelon-passwordCouldNotBeChanged',
                                      ),
                                    });
                                  }
                                } catch (error: unknown) {
                                  if (
                                    error instanceof ApolloError &&
                                    error.message === 'MACC_1'
                                  ) {
                                    notifService.send('NOTIF', {
                                      mode: 'error',
                                      content: t(
                                        'watermelon-theCurrentPasswordIsIncorrect',
                                      ),
                                    });
                                  } else {
                                    notifService.send('NOTIF', {
                                      mode: 'error',
                                      content: t('watermelon-SERVER_PROBLEM'),
                                    });
                                  }
                                }
                              },
                              validate: (isValid) => {
                                setPasswordValidForm(() => isValid);
                              },
                              change: (values) => {
                                setPasswordValues(() => values);
                                setPasswordValidSame(
                                  () =>
                                    values.newPassword ===
                                    values.confirmNewPassword,
                                );
                              },
                            }}
                            config={{
                              validateOnChange: true,
                              element: { width: 'full', way: 'vertical' },
                            }}
                          >
                            <Form.Item
                              config={{
                                name: 'currentPassword',
                                label: t('watermelon-currentPassword'),
                                rules: [{ required: true }],
                              }}
                            >
                              <Input
                                config={{ type: { name: 'password' } }}
                              ></Input>
                            </Form.Item>
                            <Space config={{ way: 'vertical', count: 2 }} />
                            <Form.Item
                              config={{
                                name: 'newPassword',
                                label: t('watermelon-newPassword'),
                                rules: [
                                  { required: true },
                                  {
                                    validator: minLengthPasswordRule,
                                  },
                                  {
                                    validator: uppercaseLetterRule,
                                  },
                                  {
                                    validator: lowercaseLetterRule,
                                  },
                                  {
                                    validator: digitRule,
                                  },
                                  {
                                    validator: specialCharRule,
                                  },
                                ],
                              }}
                            >
                              <Input
                                config={{ type: { name: 'password' } }}
                              ></Input>
                            </Form.Item>
                            <Space config={{ way: 'vertical', count: 2 }} />
                            <Form.Item
                              config={{
                                name: 'confirmNewPassword',
                                label: t('watermelon-confirmNewPassword'),
                                rules: [
                                  { required: true },
                                  {
                                    validator: minLengthPasswordRule,
                                  },
                                  {
                                    validator: uppercaseLetterRule,
                                  },
                                  {
                                    validator: lowercaseLetterRule,
                                  },
                                  {
                                    validator: digitRule,
                                  },
                                  {
                                    validator: specialCharRule,
                                  },
                                ],
                              }}
                            >
                              <Input
                                config={{ type: { name: 'password' } }}
                              ></Input>
                            </Form.Item>
                            <Space config={{ way: 'vertical', count: 2 }} />
                            <Button
                              config={{
                                size: 'small',
                                text: t('watermelon-submit'),
                                type: {
                                  value: 'submit',
                                },
                                disabled: !(
                                  passwordValidFormat && passwordValidSame
                                ),
                              }}
                            ></Button>
                            <Space config={{ way: 'vertical', count: 0.5 }} />

                            {passwordValidFormat && !passwordValidSame && (
                              <Col
                                config={{
                                  width: 'full',
                                  vertical: 'center',
                                  horizontal: 'center',
                                }}
                              >
                                <Write
                                  data={{
                                    item: t('watermelon-passwordsAreDifferent'),
                                  }}
                                  config={{
                                    color: 'error',
                                    mode: 'key-small-light',
                                  }}
                                />
                              </Col>
                            )}
                          </Form>
                        ),
                      },
                    }
                  : null,
                lang
                  ? {
                      key: 'lang',
                      label: t('watermelon-update-lang'),
                      icon: 'faEarthEuropeSolid',
                      extra: {
                        type: 'drawer',
                        node: (
                          <Form
                            data={{
                              value: {
                                lang: langSelected,
                              },
                            }}
                            handleEvent={{
                              change: (value: any) => {
                                dispatch('SELECTED_LANG', {
                                  lang: value.lang as string,
                                });
                                dispatch('UPDATE_LANG', {
                                  lang: value.lang as string,
                                });
                              },
                            }}
                            config={{
                              element: { width: 'full', way: 'vertical' },
                            }}
                          >
                            <Form.Item
                              data={{ defaultValue: langSelected }}
                              config={{ name: 'lang' }}
                            >
                              <Select
                                data={{
                                  items: langItems
                                    ? langItems.map(
                                        (
                                          lang: string,
                                        ): {
                                          label: React.ReactNode;
                                          value: string;
                                        } => ({
                                          label: (
                                            <div className="select-lang-item">
                                              <img
                                                src={`https://flagsapi.com/${Static.flagLang(
                                                  lang,
                                                )}/shiny/32.png`}
                                              ></img>
                                              <span>
                                                {Static.langLabeled(lang)}
                                              </span>
                                            </div>
                                          ),
                                          value: lang,
                                        }),
                                      )
                                    : [],
                                }}
                              ></Select>
                            </Form.Item>
                          </Form>
                        ),
                      },
                    }
                  : null,
                theme
                  ? {
                      key: 'theme',
                      label: t('watermelon-update-theme'),
                      icon: 'faPaintRollerSolid',
                      extra: {
                        type: 'drawer',
                        node: (
                          <Form
                            data={{
                              value: {
                                theme: ThemeStore.theme,
                              },
                            }}
                            handleEvent={{
                              change: (value: any) => {
                                ThemeStore.setTheme(value.theme);
                              },
                            }}
                            config={{
                              element: { width: 'full', way: 'vertical' },
                            }}
                          >
                            <Form.Item config={{ name: 'theme' }}>
                              <RadioGroup>
                                <Radio
                                  data={{
                                    value: 'light',
                                    label: t('watermelon-light'),
                                  }}
                                ></Radio>
                                <Radio
                                  data={{
                                    value: 'dark',
                                    label: t('watermelon-dark'),
                                  }}
                                ></Radio>
                              </RadioGroup>
                            </Form.Item>
                          </Form>
                        ),
                      },
                    }
                  : null,
              ] as Array<HeaderGroupSectionType.Data.Item | null>,
            },
            {
              key: 'groupVersion',
              disabled: versions?.environment === 'production',
              items: [
                {
                  key: 'environnement',
                  label: t('watermelon-environment'),
                  icon: 'faSeedlingSolid',
                  extra: {
                    type: 'value',
                    text: versions?.environment || t('watermelon-not-defined'),
                  },
                },
                {
                  key: 'watermelon',
                  label: t('watermelon'),
                  icon: 'faCodeForkSolid',
                  extra: {
                    type: 'value',
                    text: versions?.watermelon || t('watermelon-not-defined'),
                  },
                },
                {
                  key: 'front',
                  label: t('watermelon-application'),
                  icon: 'faCodeMergeSolid',
                  extra: {
                    type: 'value',
                    text: versions?.front || t('watermelon-not-defined'),
                  },
                },
              ],
            },
            {
              key: 'link',
              disabled: !isConnected,
              items: [
                {
                  key: 'logout',
                  label: t('watermelon-logout'),
                  icon: 'faArrowRightFromBracketSolid',
                  extra: {
                    type: 'event',
                    event: () => {
                      console.log('evnet');
                      logout();
                    },
                  },
                },
              ],
            },
          ]
            .filter(({ disabled = false }) => !disabled)
            .map(({ items, key, disabled }, index) => {
              return (
                <HeaderGroupSection
                  handleEvent={{
                    close: () => {
                      drawer?.close();
                    },
                  }}
                  key={index}
                  data={{ items: items as any, key, disabled }}
                />
              );
            });
        }}
      </DrawerContext.Consumer>
    )
  );
};

export declare namespace HeaderType {
  type Props = {
    className?: string;
    config?: {
      position?: Extract<Position, 'absolute' | 'fixed' | 'static' | 'sticky'>;
      logo?: HeaderType.Config.Logo;
      shortcut?: HeaderType.Config.Shortcut[];
      extra?: HeaderType.Config.Extra;
    };
  };

  namespace Config {
    type Extra = React.ReactNode;
    type Logo = React.ReactNode;
    type Shortcut = {
      icon?: HeaderGroupSectionType.Data.Item['icon'];
      label: HeaderGroupSectionType.Data.Item['label'];
      event: () => void;
    };
  }
}

export const Header = observer(
  ({
    className = '',
    config: { position = 'static', logo, shortcut, extra } = {},
  }: HeaderType.Props) => {
    const { isDesktop } = useDevice();
    const navigate = useNavigate();

    const { AuthStore, GlobalStore, SSOStore } = useRootStore();

    return (
      <header
        id="header"
        className={`
          header 
          header--position--${position}

          ${className}
        `}
      >
        <div className="header__logo">{logo}</div>
        <div className="header__extra">{extra}</div>
        {isDesktop &&
          (!SSOStore.authenticated ? (
            <AccountManager
              account={{
                firstName: AuthStore?.me?.firstName,
                lastName: AuthStore?.me?.lastName,
              }}
              group={{
                isConnected: !!AuthStore.me,
                features: {
                  logout: () => {
                    console.log('logiut authstore classic');
                    AuthStore.logout();
                    navigate('/');
                  },
                  lang: true,
                  shortcut,
                  theme: true,
                  updatePassword: GlobalStore.account?.updatePassword,
                  tfa: GlobalStore.account?.tfa
                    ? {
                        state: !!AuthStore?.me?.tfaActivated!,
                        email: AuthStore?.me?.username as string,
                      }
                    : undefined,
                  versions: {
                    environment: GlobalStore?.version?.env,
                    front: GlobalStore?.version?.front,
                    watermelon: GlobalStore?.version?.watermelon,
                  },
                },
              }}
            />
          ) : SSOStore?.mode === 'parent' ? (
            <AccountManager
              account={{
                firstName: SSOStore?.account?.firstName,
                lastName: SSOStore?.account?.lastName,
              }}
              group={{
                isConnected: !!SSOStore.authenticated,
                features: {
                  lang: true,
                  shortcut: shortcut,
                  theme: true,
                  tfa: undefined,
                  updatePassword: true,
                  logout: () => {
                    console.log('logiut ss0');
                    SSOStore.logout();
                  },
                  versions: {
                    environment: GlobalStore?.version?.env,
                    front: GlobalStore?.version?.front,
                    watermelon: GlobalStore?.version?.watermelon,
                  },
                },
              }}
            />
          ) : (
            <AccountSwitchApp
              account={{
                firstName: SSOStore?.account?.firstName,
                lastName: SSOStore?.account?.lastName,
              }}
              roles={SSOStore?.roles}
              resources={SSOStore?.resources}
            ></AccountSwitchApp>
          ))}
      </header>
    );
  },
);

const AccountManager = observer(
  ({
    group,
    account,
  }: {
    group: HeaderGroupType.Props;
    account?: {
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
    } | null;
  }) => {
    const { t } = useTranslation();
    const { isDesktop } = useDevice();
    const { SSOStore } = useRootStore();

    return (
      <div className="accountManager">
        <Drawer
          data={
            <div className="accountManager__account">
              {account && Object.values(account).filter(Boolean).length > 0 && (
                <div className="accountManager__account__identity">
                  <AccountAvatar colorInverse={false} account={account} />
                  <div className="accountManager__account__identity__names">
                    <AccountNamise colorInverse={false} account={account} />
                    <Write
                      data={{
                        item:
                          account?.email || t('watermelon-not-defined-email'),
                      }}
                      config={{ mode: 'namise-description' }}
                    />
                  </div>
                </div>
              )}
              <HeaderGroup
                features={group.features}
                isConnected={group.isConnected}
              ></HeaderGroup>
            </div>
          }
          config={{
            size: isDesktop ? 'xsmall' : 'large',
            placement: isDesktop ? 'right' : 'bottom',
          }}
        >
          {Object.values(account || {}).filter(Boolean).length ? (
            SSOStore.instance ? (
              <AccountPresent account={account} variant={'SSO_PARENT'} />
            ) : (
              <AccountPresent account={account} variant={'CLASSIC'} />
            )
          ) : (
            <AccountPresent account={account} variant={'UNCONNECTED'} />
          )}
        </Drawer>
      </div>
    );
  },
);

const AccountSwitchApp = ({
  account,
  roles = [],
  resources,
}: {
  account?: {
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  roles?: Array<string>;
  resources: SSOContextType['resources'];
}) => {
  return (
    <Popover
      config={{ padding: false, placement: 'topRight' }}
      data={
        <div className="accountSwitchApp">
          <div className="accountSwitchApp__body">
            {resources?.modules
              .filter(({ url }) => !url?.startsWith(window.location.origin))
              .map(
                ({ name, label, url, canUse, color, description, title }) => (
                  <div
                    onClick={() => {
                      if (canUse) window.location.replace(url);
                    }}
                    style={{ backgroundColor: color }}
                    key={name}
                    className={`
                      accountSwitchApp__body__app 
                      accountSwitchApp__body__app--${
                        canUse ? 'active' : 'inactive'
                      }
                      
                      `}
                  >
                    <Write
                      data={{ item: label }}
                      config={{ mode: 'namise', wrap: true, align: 'center' }}
                    ></Write>
                  </div>
                ),
              )}
          </div>
          <div
            onClick={() => {
              if (resources?.parentUrl) {
                window.location.replace(resources.parentUrl);
              }
            }}
            className="accountSwitchApp__footer"
          >
            <Write
              data={{ item: 'Go to Presto Unity' }}
              config={{ mode: 'namise', color: 'primary-over' }}
            ></Write>
            <Icon
              config={{
                type: 'faArrowRightSolid',
                color: 'primary-over',
                size: 'medium',
              }}
            />
          </div>
        </div>
      }
    >
      <AccountPresent account={account} variant="SSO_CHILDREN" />
    </Popover>
  );
};

//! Text & Icon
const AccountPresent = ({
  variant,
  account,
}: {
  account?: {
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  variant: 'UNCONNECTED' | 'SSO_PARENT' | 'SSO_CHILDREN' | 'CLASSIC';
}) => {
  const { lang, t } = useTranslation();

  const currentVariant = useMemo(() => {
    switch (variant) {
      case 'UNCONNECTED':
        return (
          <>
            <Write
              data={{
                item: t('watermelon-params'),
              }}
              config={{ mode: 'link', color: 'primary-over' }}
            ></Write>
            <Icon
              config={{
                type: 'faGearSolid',
                color: 'primary-over',
              }}
            ></Icon>
          </>
        );
      case 'SSO_PARENT':
        return (
          <>
            <AccountAvatar
              colorInverse={true}
              account={{
                firstName: account?.firstName,
                lastName: account?.lastName,
              }}
            />
            <AccountNamise
              colorInverse={true}
              account={{
                firstName: account?.firstName,
                lastName: account?.lastName,
              }}
            ></AccountNamise>
          </>
        );

      case 'SSO_CHILDREN':
        return (
          <>
            <AccountAvatar
              colorInverse={true}
              account={{
                firstName: account?.firstName,
                lastName: account?.lastName,
              }}
            />
            <AccountNamise
              colorInverse={true}
              account={{
                firstName: account?.firstName,
                lastName: account?.lastName,
              }}
            />
            <Icon
              config={{
                type: 'faCubesStackedSolid',
                color: 'primary-over',
                size: 'large',
              }}
            />
          </>
        );

      case 'CLASSIC':
        return (
          <>
            <Write
              data={{
                item: t('watermelon-my-space'),
              }}
              config={{ mode: 'link', color: 'primary-over' }}
            ></Write>
            <AccountAvatar
              colorInverse={true}
              account={{
                firstName: account?.firstName,
                lastName: account?.lastName,
              }}
            ></AccountAvatar>
          </>
        );
    }
  }, [variant, lang, account]);

  return <div className="accountPresent">{currentVariant}</div>;
};

const AccountNamise = observer(
  ({
    account,
    colorInverse,
  }: {
    account: { firstName?: string | null; lastName?: string | null };
    colorInverse: boolean;
  }) => {
    const { lang, t } = useTranslation();
    const { SSOStore } = useRootStore();

    const fullName = useMemo(() => {
      return account?.firstName && account?.lastName
        ? `${toCapitalizeCase(account?.firstName)} ${toCapitalizeCase(
            account?.lastName,
          )}`
        : account?.firstName && !account?.lastName
        ? `${toCapitalizeCase(account?.firstName)}`
        : !account?.firstName && account?.lastName
        ? `${toCapitalizeCase(account?.lastName)} `
        : t('watermelon-not-defined-names');
    }, [account, lang]);

    return (
      <Write
        data={{
          item: fullName,
        }}
        config={{
          color: colorInverse ? 'primary-over' : 'primary',
          mode: 'link',
        }}
      />
    );
  },
);

const AccountAvatar = ({
  account,
  colorInverse,
}: {
  account: { firstName?: string | null; lastName?: string | null };
  colorInverse: boolean;
}) => {
  return (
    <Avatar
      data={{
        firstName: account?.firstName || '',
        lastName: account?.lastName || '',
      }}
      config={{
        backgroundColor: colorInverse ? 'primary-over' : 'primary',
        color: colorInverse ? 'primary' : 'primary-over',
      }}
    ></Avatar>
  );
};
